import React, { useEffect, useState } from 'react';
import './EnergyCalculation.scss';
import { Button } from '../../../../Components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Pathname } from '../../../../Router/Pathname';
import Modal from '../../../../Components/Common/Modal/Modal';
import walleticon from '../../../../Assets/pngIcon/walletXl.png';
import Stationbanner from './StationBanner/Stationbanner';
import EnergyHeader from './EnergyHeader/EnergyHeader';
import EnergyModal from './EnergyModal/EnergyModal';
import { calculateUnit, getEndPlanDetails } from './Redux/action';
import IncDecrement from '../../../../Components/Common/IncDecrement/InDecrement';
import { setPrice, setCharge, resetChargingPointId } from './Redux/EnergySlice';
import SubscriptionExpireModel from '../../../../Components/Common/SubscriptionExpireModel/SubscriptionExpireModel';
import TerminationIcon from '../../../../../src/Assets/terminationImage.png';
import ExpiryIcon from '../../../../../src/Assets/expiryImage.png';

const EnergyCalculation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { energy } = useSelector((store) => store);

    const {
        redirectToAddMoney,
        isZeroTarrif,
        calculatedEnergy,
        calculatedAmount,
        loading,
        ConnectorDetails,
        subscriptionDetails,
    } = energy;
    // const subscriptionDetails = '2025-01-10T18:30:00.000Z';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [amountVal, setAmountVal] = useState(calculatedAmount);
    const [energyVal, setEnergyVal] = useState(calculatedEnergy);
    const [subscriptionExpireModel, setSubscriptionExpireModel] = useState(false);
    const [subscriptionTerminationModel, setSubscriptionTerminationModel] =
        useState(false);
    const [isBannerVisible, setIsBannerVisible] = useState(false); // State to control banner visibility

    // Close banner handler
    const handleCloseBanner = () => {
        setIsBannerVisible(false);
    };

    useEffect(() => {
        const currentDate = new Date();
        const planEndDate = new Date(subscriptionDetails);

        // Reset both dates to remove the time portion (set to 00:00:00)
        currentDate.setHours(0, 0, 0, 0);
        planEndDate.setHours(0, 0, 0, 0);

        // Calculate the difference in days
        const daysBeforeEnd = (planEndDate - currentDate) / (1000 * 60 * 60 * 24);

        // Check if the banner should be visible
        if (daysBeforeEnd > 0 && daysBeforeEnd <= 30 && daysBeforeEnd >= 3) {
            setIsBannerVisible(true);
        } else {
            setIsBannerVisible(false);
        }
    }, [subscriptionDetails]);

    useEffect(() => {
        if (subscriptionDetails) {
            const planEndDate = new Date(subscriptionDetails);
            const today = new Date();

            // Normalize both dates to midnight (00:00:00)
            planEndDate.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            // Calculate days left
            const daysLeft = Math.ceil((planEndDate - today) / (1000 * 60 * 60 * 24));

            // Condition 1: Open Expiration Modal if it's going to expire in the next 3 days or today
            if (daysLeft <= 3 && daysLeft >= 0) {
                setSubscriptionExpireModel(true);
            }

            // Condition 2: Open Termination Modal if the date is in the past
            if (daysLeft < 0) {
                setSubscriptionTerminationModel(true);
            }
        }
    }, [subscriptionDetails]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeExpirationModal = () => {
        setSubscriptionExpireModel(false);
    };

    const closeTerminationModal = () => {
        setSubscriptionTerminationModel(false);
    };

    const handleRecharge = () => {
        if (!redirectToAddMoney) {
            navigate(Pathname.INTIATE_CHARGING, {
                state: {
                    calculatedEnergy: calculatedEnergy,
                    calculatedAmount: calculatedAmount,
                    isZeroTarrif: isZeroTarrif,
                    connector_id: location?.state?.connector_id,
                },
            });
        } else if (redirectToAddMoney) {
            openModal();
        }
    };

    /*=============================================
    =            Amount Section             =
    =============================================*/

    const handleAmountChange = (e) => {
        const newAmount = e.target.value;
        setAmountVal(newAmount);
        dispatch(setPrice(newAmount));
        // if (!newAmount) {
        //     setEnergyVal('');
        //     dispatch(setCharge(''));
        // }
    };
    const handleIncrementAmount = () => {
        const newValue = Math.max(parseInt(calculatedAmount) + 10, 0);
        setAmountVal(newValue);
        dispatch(setPrice(newValue));
        dispatch(setCharge(calculatedEnergy));
    };
    const handleDecrementAmount = () => {
        const newValue = Math.max(parseInt(calculatedAmount) - 10, 0);
        setAmountVal(newValue);
        dispatch(setPrice(newValue));
        dispatch(setCharge(calculatedEnergy));
    };
    /*=============================================
    =            Energy Section             =
    =============================================*/

    const handleEnergyChange = async (e) => {
        const newUnit = e.target.value;
        setEnergyVal(newUnit);
        dispatch(setCharge(newUnit));
        // if (!newUnit) {
        //     setAmountVal('');
        //     dispatch(setPrice(''));
        // }
    };
    const handleIncrementEnergy = () => {
        const newValue = Math.max(parseInt(calculatedEnergy) + 1, 0);
        setEnergyVal(newValue);
        dispatch(setCharge(newValue));
        dispatch(setPrice(calculatedAmount));
    };
    const handleDecrementEnergy = () => {
        const newValue = Math.max(parseInt(calculatedEnergy) - 1, 0);
        setEnergyVal(newValue);
        dispatch(setCharge(newValue));
        dispatch(setPrice(calculatedAmount));
    };

    /*=============================================
    =            UseEffects             =
    =============================================*/

    useEffect(() => {
        const connectorId = sessionStorage.getItem('connector_id');
        if (connectorId !== undefined && connectorId !== null) return;
        sessionStorage.setItem('connector_id', location?.state?.connector_id);
    }, []);

    useEffect(() => {
        if (amountVal && !isZeroTarrif) {
            const debouncedAmountChange = setTimeout(() => {
                dispatch(
                    calculateUnit({
                        connector_id: location?.state?.connector_id || location?.state,
                        amount: amountVal,
                    })
                );
            }, 800);
            return () => clearTimeout(debouncedAmountChange);
        }
    }, [amountVal]);

    useEffect(() => {
        if (energyVal && !isZeroTarrif) {
            const debouncedUnitChange = setTimeout(() => {
                dispatch(
                    calculateUnit({
                        connector_id: location?.state?.connector_id || location?.state,
                        unit: energyVal,
                    })
                );
            }, 800);
            return () => clearTimeout(debouncedUnitChange);
        }
    }, [energyVal]);
    console.log(ConnectorDetails?.connector_data?.charging_point_id, 'idds');
    useEffect(() => {
        if (ConnectorDetails?.connector_data?.charging_point_id) {
            dispatch(
                getEndPlanDetails(ConnectorDetails?.connector_data?.charging_point_id)
            );
        }
    }, [ConnectorDetails?.connector_data?.charging_point_id]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', options);
    };

    return (
        <>
            {isBannerVisible && (
                <div className="Navbar__standard__banner">
                    <span className="Navbar__banner-message">
                        Subscription will end on{' '}
                        {new Date(subscriptionDetails).toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            timeZone: 'UTC',
                        })}
                        . To avoid any discontinued charging service, please contact the
                        building management.
                    </span>
                    <button className="Navbar__banner-close" onClick={handleCloseBanner}>
                        ×
                    </button>
                </div>
            )}
            <main className="EnergyCalculation">
                <div>
                    <EnergyHeader
                        setSubscriptionExpireModel={setSubscriptionExpireModel}
                        setSubscriptionTerminationModel={setSubscriptionTerminationModel}
                        setIsBannerVisible={setIsBannerVisible}
                    />
                    <Stationbanner />

                    <div className="EnergyCalculation__Label">
                        Enter{' '}
                        <span className="EnergyCalculation__LabelBold">
                            {isZeroTarrif ? 'Energy' : 'Amount or Energy'}
                        </span>{' '}
                        to Continue
                    </div>
                    <IncDecrement
                        label="No. of units (kW)"
                        placeholder="No. of units (kW)"
                        value={calculatedEnergy}
                        onChange={handleEnergyChange}
                        handleDecrement={handleDecrementEnergy}
                        handleIncrement={handleIncrementEnergy}
                        loading={loading}
                        unit={'kW'}
                    />

                    {!isZeroTarrif && (
                        <IncDecrement
                            label="Amount (in ₹-Incl. of taxes)"
                            placeholder={'Cost (in ₹)'}
                            value={calculatedAmount}
                            onChange={handleAmountChange}
                            handleDecrement={handleDecrementAmount}
                            handleIncrement={handleIncrementAmount}
                            loading={loading}
                            unit={'₹'}
                            disabled={isZeroTarrif}
                        />
                    )}
                    {isZeroTarrif && (
                        <div className="EnergyCalculation__isZeroTarrif">
                            The station you are using is Charge Free, please add energy to
                            be delivered to continue:
                        </div>
                    )}
                </div>
                <div className="EnergyCalculation__RechargeButton">
                    <Button
                        text={'Proceed'}
                        glow={calculatedEnergy && calculatedAmount ? true : false}
                        disabled={
                            !isZeroTarrif
                                ? !calculatedEnergy || !calculatedAmount
                                : !calculatedEnergy
                        }
                        onClick={handleRecharge}
                        variant="primary"
                    />
                </div>
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    title={<img src={walleticon} alt="wallet" />}
                    modalClass="IntiateCharging--custom-modal"
                    overlayClass="custom-overlay"
                    closeButtonClass="custom-close-button"
                    disableClose={true}
                >
                    <EnergyModal connector_id={location?.state} />
                </Modal>
                {subscriptionExpireModel && (
                    <Modal
                        isOpen={subscriptionExpireModel}
                        title={<img src={ExpiryIcon} alt="Expiry" width="200px" />}
                        modalClass="IntiateCharging--custom-modal"
                        closeButtonClass="custom-close-button"
                        disableClose={true}
                    >
                        <SubscriptionExpireModel
                            heading="Subscription Expiry Alert"
                            content={`Subscription will end on ${formatDate(
                                subscriptionDetails
                            )}. To avoid any discontinued charging service, please contact the building management.`}
                            containerClassName="customContainerStyle"
                            closePopup={closeExpirationModal}
                        />
                    </Modal>
                )}
                {subscriptionTerminationModel && (
                    <Modal
                        isOpen={subscriptionTerminationModel}
                        title={
                            <img src={TerminationIcon} alt="Termination" width="80px" />
                        }
                        modalClass="IntiateCharging--custom-modal"
                        closeButtonClass="custom-close-button"
                        disableClose={true}
                    >
                        <SubscriptionExpireModel
                            heading="EV Charging Service Expired"
                            content="EV Charging services terminated. Please contact building management for continued services."
                            navigateTo={Pathname.HOME}
                            setSubscriptionExpireModel={setSubscriptionExpireModel}
                            setSubscriptionTerminationModel={setSubscriptionTerminationModel}
                        />
                    </Modal>
                )}
            </main>
        </>
    );
};

export default EnergyCalculation;
