import React, { useEffect } from 'react';
import ArrowLeft from '../../../../../Assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import './EnergyHeader.scss';
import { useDispatch } from 'react-redux';
import { resetChargingPointId, resetSubscriptionDetails } from '../Redux/EnergySlice';
const EnergyHeader = ({setSubscriptionExpireModel,setSubscriptionTerminationModel,setIsBannerVisible}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return (
        <div className="EnergyHeader--btn">
            <span
                onClick={() => {
                    navigate(-1);
                    setSubscriptionExpireModel(false);
                    setSubscriptionTerminationModel(false);
                    setIsBannerVisible(false);
                    dispatch(resetSubscriptionDetails());
                    dispatch(resetChargingPointId());
                }}
            >
                <ArrowLeft />
            </span>
            <p>Start Charging</p>
        </div>
    );
};

export default EnergyHeader;
