import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the navigation hook
import Button from '../Button/Button';
import './SubscriptionExpireModel.scss';
import { useDispatch } from 'react-redux';
import { resetChargingPointId, resetSubscriptionDetails } from '../../../Views/AfterAuth/StartChargeingView/EnergyCalculations/Redux/EnergySlice';

const SubscriptionExpireModel = ({ heading, content, contentClassName, containerClassName, navigateTo,closePopup,setSubscriptionExpireModel,setSubscriptionTerminationModel }) => {
    const navigate = useNavigate(); // Initialize the navigation function
     const dispatch = useDispatch();

    const handleNavigation = () => {
        if (navigateTo) {
            navigate(navigateTo);
             // Navigate to the path provided in props
             setSubscriptionExpireModel(false);
             setSubscriptionTerminationModel(false);
             dispatch(resetChargingPointId());
             dispatch(resetSubscriptionDetails());
        } 
        if (closePopup) {
            closePopup(); // Close the popup when button is clicked
        }
    };

    return (
        <div className={`SubscriptionExpire--content ${containerClassName || ''}`}>
            <p className="funds">{heading}</p>
            <p className={`fundInfo ${contentClassName || ''}`}>{content}</p>
            <div>
                <Button 
                    className="addWallet" 
                    text="OK" 
                    onClick={handleNavigation} // Add click handler for navigation
                />
            </div>
        </div>
    );
};

export default SubscriptionExpireModel;
